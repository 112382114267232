import { useToast } from "vue-toastification";
import Menu from './menu/Menu.vue'

export default {
  name: "Admin",

  components: {
    Menu,
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  

  data: () => ({
    url: '//api.testme.fans',
   //  url: "http://localhost:1337",
    menu: [],
    toppings: [],
    updateForm: false,
    value: null,
    options: ["Batman", "Robin", "Joker"],
    typeMenu: [
      { name_ru: "Завтраки", name_en: "Breakfast" },
      { name_ru: "Основное меню", name_en: "MainMenu" },
      { name_ru: "Поке / суши / татаки", name_en: "Sushi" },
      { name_ru: "Напитки", name_en: "Beverages" },
    ],
    activeBar: false,
    activeClassFood: null,
    formData: {},
    postType: "",
    errorsInput: false,
    selectedtypeMenu: null,
    sortedMenu: [],
    optionToast: {
      timeout: 3000,
      position: "top-left",
    },
    current: "tabA",
    valueToppings: [],
  }),

  mounted() {
    this.getMenu();
    this.getToppings();
  },

  methods: {
    getMenu() {
      this.axios
        .get(`${this.url}/menus`)
        .then((res) => {
          this.menu = res.data.data;
          this.sortedMenu = this.menu;
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        }); 
    },

    sendMenu() {
      this.formData.toppings = this.valueToppings;
      this.axios
        .post(`${this.url}/menus/`, this.formData)
        .then(() => {
          this.toast.success("Блюдо в меню успешно добавлено", {
            timeout: 3000,
            position: "top-left",
          });
          this.getMenu();
          this.closeActionBar();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateMenu(id) {
      this.formData.toppings = this.valueToppings;
      this.axios
        .put(`${this.url}/menus/${id}`, this.formData)
        .then(() => {
          this.toast.success("Блюдо в меню успешно изменено", {
            timeout: 3000,
            position: "top-left",
          });

          this.getMenu();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteMenu(id) {
      this.axios
        .delete(`${this.url}/menus/${id}`)
        .then(() => {
          this.toast.success("Блюдо успешно удалено", {
            timeout: 3000,
            position: "top-left",
          });
          this.getMenu();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    getToppings() {
      this.axios
        .get(`${this.url}/toppings`)
        .then((res) => {
          this.toppings = res.data.data;
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    sendTopping() {
      this.axios
        .post(`${this.url}/toppings/`, this.formData)
        .then(() => {
          this.toast.success("Топпинг успешно добавлен", {
            timeout: 3000,
            position: "top-left",
          });
          this.getToppings();
          this.closeActionBar();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateTopping(id) {
      this.axios
        .put(`${this.url}/toppings/${id}`, this.formData)
        .then(() => {
          this.toast.success("Топпинг успешно изменен", {
            timeout: 3000,
            position: "top-left",
          });

          this.getToppings();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteTopping(id) {
      this.axios
        .delete(`${this.url}/toppings/${id}`)
        .then(() => {
          this.toast.success("Топпинг успешно удален", {
            timeout: 3000,
            position: "top-left",
          });
          this.getToppings();
        })
        .catch((error) => {
          this.toast.error(error.request.statusText, this.optionToast);
        });
    },

    openActionBar(actionType, postType, data) {
      this.activeBar = true;
      this.postType = postType;

      if (actionType === "edit") {
        this.formData = data;
        this.updateForm = true;

        this.formData.toppings.forEach((topping, index) => {
          this.valueToppings[index] = topping.id;
        });
      }
    },

    closeActionBar() {
      this.updateForm = false;
      this.activeBar = false;
      this.formData = {};
      this.valueToppings = [];
    },

    sortByFood(typeM, i) {
      this.activeClassFood = i;
      if (typeM.length) {
        this.sortedMenu = [];
        this.menu.map((item) => {
          if (item.type_food === typeM) {
            this.sortedMenu.push(item);
          }
        });
      } else {
        this.sortedMenu = this.menu;
      }
    },

    show(name) {
      this.current = name;
    },

    isCurrent(name) {
      return this.current == name;
    },
  },

  computed: {
    filteredMenu() {
      return this.sortedMenu;
    },
  },
};