import {
  apiUrl
} from '../../../variable'

export default {
  name: 'MenuCayegories',

  data: () => ({
    url: apiUrl,
    token: {
      headers: {
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    },
    categoryMenu: [],

  }),

  mounted() {
    this.getMenuCategory();
  },

  methods: {

    getMenuCategory() {
      this.axios
        .get(`${this.url}/category-menus?pagination%5Blimit%5D=1000&populate=%2A`, this.token)
        .then((res) => {
          this.categoryMenu = res.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteMenuCategory(id) {
      this.axios
        .delete(`${this.url}/category-menus/${id}`, this.token)
        .then(() => {
          this.$toast.success("Категория меню успешно удалена", {
            timeout: 3000,
            position: "top-right",
          });
          this.getMenuCategory();
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },
  },
}