import {
  apiUrl
} from '../../../../variable'

export default {
  name: 'ActionTopping',

  data: () => ({
    url: apiUrl,
    token: {
      headers: {
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    },
    formData: {
      attributes: {
        title_ru: '',
        title_et: '',
        title_en: '',
        price: null
      }
    },
    topping: {},
    updateForm: false,
  }),

  mounted() {
    if (this.$route.params.id !== undefined) {
      this.updateForm = true;
      this.getSingleTopping();
    }
  },

  methods: {

    getSingleTopping() {
      this.axios
        .get(`${this.url}/toppings/${this.$route.params.id}`, this.token)
        .then((res) => {
          this.topping = res.data.data;
          this.formData = this.topping
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    sendTopping() {
      const data = {
        data: {
          ...this.formData.attributes
        }
      };

      this.axios
        .post(`${this.url}/toppings/`, data, this.token)
        .then(() => {
          this.$toast.success("Топпинг успешно добавлен", {
            timeout: 3000,
            position: "top-right",
          });
          this.formData = {};
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateTopping(id) {
      const data = {
        data: {
          ...this.formData.attributes
        }
      };

      this.axios
        .put(`${this.url}/toppings/${id}`, data, this.token)
        .then(() => {
          this.$toast.success("Топпинг успешно изменен", {
            timeout: 3000,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },
  },

}