import {
  apiUrl
} from '../../../../variable'

export default {
  name: 'ActionMenu',

  data: () => ({
    url: apiUrl,
    token: {
      headers: {
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    },
    formData: {
      attributes: {
        title_ru: '',
        title_et: '',
        title_en: '',
        description_ru: '',
        description_et: '',
        description_en: '',
        volume: null,
        price: null,
        menu_category: {
          data: {}
        },
        menu_subcategory: {
          data: {}
        },
        toppings: {
          data: {}
        },
      }
    },
    toppings: [],
    updateForm: false,
    menu: {},

    items: [
      'Breakfast',
      'MainMenu',
      'Sushi',
      'Beverages',
    ],

    categoryMenu: [],
    categoryMenuParent: [],
    categoryMenuChild: [],
  }),

  mounted() {
    if (this.$route.params.id !== undefined) {
      this.updateForm = true;
      this.getSingleMenu();
    }
    this.getToppings();
    this.getMenuCategory();
  },

  methods: {

    getSingleMenu() {
      this.axios
        .get(`${this.url}/menus/${this.$route.params.id}?populate=%2A`, this.token)
        .then((res) => {
          this.menu = res.data.data;
          this.formData = this.menu;
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    getToppings() {
      this.axios
        .get(`${this.url}/toppings`, this.token)
        .then((res) => {
          this.toppings = res.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    sendMenu() {
      const data = {
        data: {
          ...this.formData.attributes
        }
      };

      this.axios
        .post(`${this.url}/menus/`, data, this.token)
        .then(() => {
          this.$toast.success("Блюдо в меню успешно добавлено", {
            timeout: 3000,
            position: "top-right",
          });
          this.formData = {};
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateMenu(id) {
      const data = {
        data: {
          ...this.formData.attributes
        }
      };

      this.axios
        .put(`${this.url}/menus/${id}`, data, this.token)
        .then(() => {
          this.$toast.success("Блюдо в меню успешно изменено", {
            timeout: 3000,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    getMenuCategory() {
      this.axios
        .get(`${this.url}/category-menus?pagination%5Blimit%5D=1000&populate=%2A`, this.token)
        .then((res) => {
          this.categoryMenu = res.data.data;

          this.categoryMenuParent = this.categoryMenu.filter(item => item.attributes.parent.data === null);
          this.categoryMenuChild = this.categoryMenu.filter(item => item.attributes.childs.data !== null);
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },
  },

}