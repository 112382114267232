<template>
  <div class="container">
    <v-toolbar v-show="$route.name !== 'Login'">
      <div class="logo">
        <img src="./assets/images/logo.svg" alt="" />
        <v-btn
          target="_blank"
          href="https://simplerestoweb.pages.dev/menu"
          class="secondary"
          >На сайт</v-btn
        >
        <v-btn @click="logout" class="warning">Выйти</v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn v-show="$route.name === 'Menu'" to="/actionmenu" color="success">
        Добавить позицию в меню
      </v-btn>
      <v-btn
        v-show="$route.name === 'Toppings'"
        to="/actiontopping"
        color="success"
      >
        Добавить топпинг
      </v-btn>
      <v-btn
        v-show="$route.name === 'MenuCategories'"
        to="/action-cat"
        color="success"
      >
        Добавить категорию
      </v-btn>
    </v-toolbar>
    <div :class="$route.name === 'Login' ? '' : 'wrapper'">
      <aside class="sidebar" v-show="$route.name !== 'Login'">
        <nav>
          <ul class="catmenu">
            <li>
              <router-link to="/menu" active-class="active">Меню</router-link>
            </li>
            <li>
              <router-link to="/toppings" active-class="active"
                >Топпинги</router-link
              >
            </li>
            <li>
              <router-link to="/menu-cat" active-class="active"
                >Категории меню</router-link
              >
            </li>
          </ul>
        </nav>
      </aside>

      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),

  computed: {
    id() {
      return this.$route.params.id;
    },
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },

  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/menu");
      });
    },
  },
};
</script>
