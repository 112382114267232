import { render, staticRenderFns } from "./Menu-categories.vue?vue&type=template&id=5885870e&scoped=true"
import script from "./menu-categories.js?vue&type=script&lang=js&external"
export * from "./menu-categories.js?vue&type=script&lang=js&external"
import style0 from "../admin.scss?vue&type=style&index=0&id=5885870e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5885870e",
  null
  
)

export default component.exports