import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login/Login.vue'
import Admin from '../components/admin/Admin.vue'
import Menu from '../components/admin/menu/Menu.vue'
import MenuCategories from '../components/admin/menu-categories/Menu-categories.vue'
import Toppings from '../components/admin/toppings/Toppings.vue'
import store from '../store'
import ActionMenu from '../components/admin/menu/action/ActionMenu.vue'
import ActionTopping from '../components/admin/toppings/action/ActionTopping.vue'
import ActionCat from '../components/admin/menu-categories/action/ActionCat.vue'

Vue.use(VueRouter)


const routes = [{
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresVisitor: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/toppings',
    name: 'Toppings',
    component: Toppings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/actionmenu',
    name: 'ActionMenu',
    component: ActionMenu,
    meta: {
      requiresAuth: true
    },
    children: [{
      path: '/actionmenu/:id',
      name: 'ActionMenuEdit',
      component: ActionMenu,
      props: true,
    }]
  },
  {
    path: '/actiontopping',
    name: 'ActionTopping',
    component: ActionTopping,
    meta: {
      requiresAuth: true
    },
    children: [{
      path: '/actiontopping/:id',
      name: 'ActionToppingEdit',
      component: ActionTopping,
      props: true,
    }]
  },
  {
    path: '/menu-cat',
    name: 'MenuCategories',
    component: MenuCategories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/action-cat',
    name: 'ActionCat',
    component: ActionCat,
    meta: {
      requiresAuth: true
    },
    children: [{
      path: '/action-cat/:id',
      name: 'ActionCatEdit',
      component: ActionCat,
      props: true,
    }]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {


  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next('/')
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next('/menu')
    } else {
      next()
    }
  } else {
    next();
  }
})


export default router