import {
  apiUrl
} from '../../../variable'

export default {
  name: 'Menu',

  data: () => ({
    url: apiUrl,
    token: {
      headers: {
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    },
    activeClassFood: null,
    menu: [],
    categoryMenu: [],
    sortedMenu: [],
  }),

  mounted() {
    this.getMenu();
    this.getMenuCategory();
  },

  methods: {

    getMenuCategory() {
      this.axios
        .get(`${this.url}/category-menus?pagination%5Blimit%5D=1000&populate=%2A`, this.token)
        .then((res) => {
          this.categoryMenu = res.data.data.filter(item => item.attributes.parent.data === null);
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    getMenu() {
      this.axios
        .get(`${this.url}/menus?pagination%5BwithCount%5D=false&pagination%5Blimit%5D=200&populate=%2A`, this.token)
        .then((res) => {
          this.menu = res.data.data;
          this.sortedMenu = this.menu;
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteMenu(id) {
      this.axios
        .delete(`${this.url}/menus/${id}`, this.token)
        .then(() => {
          this.$toast.success("Блюдо успешно удалено", {
            timeout: 3000,
            position: "top-left",
          });
          this.getMenu();
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    sortByFood(typeM, i) {
      this.activeClassFood = i;
      
      if (typeM.length) {
        this.sortedMenu = [];
        this.menu.map((item) => {
          if (item.attributes.menu_category.data.attributes.title_ru === typeM) {
            this.sortedMenu.push(item);
          }
        });
      } else {
        this.sortedMenu = this.menu;
      }
    },
  },

  computed: {
    filteredMenu() {
      return this.sortedMenu;
    },
  },
}