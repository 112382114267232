import { apiUrl } from '../../../variable'

export default {
  name: 'Toppings',

  data: () => ({
    url: apiUrl,
    token: {
      headers: {
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    },
    activeClassFood: null,
    toppings: [],
  }),

  mounted() {
    this.getToppings();
  },

  methods: {

    getToppings() {
      this.axios
        .get(`${this.url}/toppings`, this.token)
        .then((res) => {
          this.toppings = res.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    deleteTopping(id) {
      this.axios
        .delete(`${this.url}/toppings/${id}`, this.token)
        .then(() => {
          this.$toast.success("Топпинг успешно удален", {
            timeout: 3000,
            position: "top-left",
          });
          this.getToppings();
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },
  },

}