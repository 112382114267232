import {
  apiUrl
} from '../../../../variable'

export default {
  name: 'ActionCat',

  data: () => ({
    url: apiUrl,
    token: {
      headers: {
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    },
    formData: {
      attributes: {
        title_ru: '',
        title_et: '',
        title_en: '',
        parent: {
          data: {}
        },
      }
    },
    updateForm: false,
    cat: {},
    categoryMenu: [],
    categoryMenuFiltered: [],
  }),

  mounted() {
    if (this.$route.params.id !== undefined) {
      this.updateForm = true;
      this.getCat();
    }
    this.getMenuCategory();

  },

  methods: {

    getCat() {
      this.axios
        .get(`${this.url}/category-menus/${this.$route.params.id}?pagination%5Blimit%5D=1000&populate=%2A`, this.token)
        .then((res) => {
          this.cat = res.data.data;
          this.formData = this.cat
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    getMenuCategory() {
      this.axios
        .get(`${this.url}/category-menus?pagination%5Blimit%5D=1000&populate=%2A`, this.token)
        .then((res) => {
          this.categoryMenu = res.data.data;
        
          this.categoryMenuFiltered = this.categoryMenu.filter(item => item.attributes.parent.data === null);
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    sendCat() {
      const data = {
        data: {
          ...this.formData.attributes
        }
      };

      this.axios
        .post(`${this.url}/category-menus/`, data, this.token)
        .then(() => {
          this.$toast.success("Категория меню успешно добавлено", {
            timeout: 3000,
            position: "top-right",
          });
          this.formData = {};
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },

    updateCat(id) {
      const data = {
        data: {
          ...this.formData.attributes
        }
      };

      this.axios
        .put(`${this.url}/category-menus/${id}`, data, this.token)
        .then(() => {
          this.$toast.success("Категория меню успешно изменена", {
            timeout: 3000,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$toast.error(error.request.statusText, this.optionToast);
        });
    },
  },
}