import {
  validationMixin
} from 'vuelidate'
import {
  required,
  email
} from 'vuelidate/lib/validators'
import {
  apiUrl
} from '../../variable'

export default {
  name: 'Login',
  mixins: [validationMixin],

  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },

  data: () => ({
    url: apiUrl,
    email: '',
    password: '',
  }),

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Не корректный e-mail');
      !this.$v.email.required && errors.push('Поле обязательно к заполнению');
      return errors
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Поле обязательно к заполнению');
      return errors
    },
  },

  methods: {
    login() {
      this.$store.dispatch('retrieveToken', {
        'identifier': this.email,
        'password': this.password
      }).then(() => {
        this.$router.push('/menu')
      }).catch((error) => {
        this.$toast.error(error.request.statusText, this.optionToast, {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      });
    },
  }
}