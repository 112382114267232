import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import '../src/assets/css/style.scss'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});
Vue.config.productionTip = false


new Vue({
  vuetify,
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')