import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {
  apiUrl
} from './variable'

Vue.use(Vuex)
axios.defaults.baseURL = apiUrl

export default new Vuex.Store({

  // You can use it as state property
  state: {
    token: localStorage.getItem('jwt') || null,
  },

  getters: {
    loggedIn(state) {
      if (state.token) {
        const tokenParts = state.token.split('.');
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          const expirationTime = payload.exp * 1000; // преобразовываем в миллисекунды
          const currentTime = Date.now();

          return currentTime < expirationTime;
        }
      }

      return false;
    }
    // return state.token !== null

  },

  mutations: {
    retrieveToken(state, token) {
      state.token = token
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
  },

  actions: {
    retrieveToken(context, credideails) {
      return new Promise((resolve, reject) => {
        axios.post(`/auth/local`, {
            'identifier': credideails.identifier,
            'password': credideails.password,
          })
          .then(response => {
            const token = response.data.jwt;
            localStorage.setItem('jwt', token);
            context.commit('retrieveToken', token);
            resolve(response)
          })
          .catch(error => {
            console.error('An error occurred:', error.response);
            reject(error)
          });
      });
    },
    logout({
      commit
    }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('jwt')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }
  }
})